import request from '@/utils/request';

//详情
export function getUserSetting(data) {
  return request({
    url:'/user/setting/detail.php',
    method : 'post',
    data : data
  })
}

//修改
export function updateUserSetting(data) {
  return request({
    url :'/user/setting/updateSetting.php',
    method : 'post',
    data : data
  })
}
