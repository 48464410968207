<template>
  <el-card class="form-container" shadow="never" style="width: 98%">
    <el-form :model="userSettingModel" ref="userSettingForm" label-width="200px">
      <el-form-item label="用户单日下载次数超过：" >
        <el-input v-model="userSettingModel.down_number" min="0" type="number" style="width: 30%">
          <template v-slot:append>次</template>
        </el-input>
        <span style="margin-left: 10px;">不允许下载</span>
      </el-form-item>
      <el-form-item label="用户预览次数超过：" >
        <el-input v-model="userSettingModel.preview_number" min="0" type="number" style="width: 30%">
          <template v-slot:append>次</template>
        </el-input>
        <span style="margin-left: 10px;">不允许预览</span>
      </el-form-item>
      <el-form-item label="会员金额：">
        <el-input v-model="userSettingModel.member_money" type="number" style="width: 30%">
          <template v-slot:append>元</template>
        </el-input>
      </el-form-item>
<!--      <el-form-item label="会员单日下载次数超过：" >
        <el-input v-model="userSettingModel.member_down_number" min="0" type="number" style="width: 30%">
          <template v-slot:append>次</template>
        </el-input>
        <span style="margin-left: 10px;">不允许下载</span>
      </el-form-item>
      <el-form-item label="会员预览次数超过：" >
        <el-input v-model="userSettingModel.member_preview_number" min="0" type="number" style="width: 30%">
          <template v-slot:append>次</template>
        </el-input>
        <span style="margin-left: 10px;">不允许预览</span>
      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="handleDialogConfirm('userSettingForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>

import {getUserSetting, updateUserSetting} from '@/api/userSetting';

const defaultUserSetting = {
  id: null,
  down_number: 0,
  preview_number: 0,
  member_money: 0,
  member_down_number: 0,
  member_preview_number: 0,
};

export default {
  name: 'userSetting',
  components: {},
  data() {
    return {
      userSettingModel: Object.assign({}, defaultUserSetting),
    }
  },
  created() {

    this.getDetail();
  },
  methods: {
    getDetail(){
      let data =  {
        "dataId": 1,
      };
      getUserSetting(data).then(response=>{
            if (response.code === '0') {
              this.userSettingModel = response.data;
            }
      })
    },
    //提交操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          if (!this.userSettingModel.member_money || this.userSettingModel.member_money <=0) {
            this.$message.error("请填写会员金额")
            return
          }
          if (!this.userSettingModel.down_number) {
            this.$message.error("请填写下载次数限制")
            return
          }

          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            updateUserSetting(this.userSettingModel).then(res => {
              if (res.code === '0') {
                this.$message({
                  message: '修改成功',
                  type: 'success',
                });
              }else {
                this.$message({
                  type: "error",
                  message: res.msg
                })
              }
            });

          });
        }
      });
    },

  }
}
</script>
<style scoped>

</style>
